import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Accordion, AccordionItem } from '../components/Accordion';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1, Typography } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';

const Container = styled.div`
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Faq: FC<RouteComponentProps> = (props) => {
  const staticData = useStaticQuery(graphql`
    query {
      faqsSection1: file(relativePath: { eq: "faqs-section-1.png" }) {
        childImageSharp {
          fixed(width: 548) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      faqsSection2: file(relativePath: { eq: "faqs-section-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection31: file(relativePath: { eq: "faqs-section-3-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection32: file(relativePath: { eq: "faqs-section-3-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection41: file(relativePath: { eq: "faqs-section-4-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection42: file(relativePath: { eq: "faqs-section-4-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection43: file(relativePath: { eq: "faqs-section-4-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection44: file(relativePath: { eq: "faqs-section-4-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection51: file(relativePath: { eq: "faqs-section-5-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection52: file(relativePath: { eq: "faqs-section-5-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <PublicRoute {...props}>
      <Container>
        <SEO title="FAQs" />
        <H1 className="mb-24" color="#051392">
          FAQs
        </H1>

        <Accordion defaultOpenIndex={Number(props.location?.hash.substring(1))}>
          <AccordionItem title="What if I use line numbers in my citations in the document to be linked (brief, motion, petition, or etc.). Can <sup>&reg;</sup> accommodate line numbers?">
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process documents to be linked that contain line numbers
              citations. However, the practitioner must follow some specific guidelines. These
              guidelines are in addition to guidelines specified in the first question of this FAQ.
            </Typography>

            <Typography className="pb-6">
              Anatomy of a simple citation containing line numbers:
            </Typography>

            <div className="flex items-center justify-center">
              <Img fixed={staticData.faqsSection1.childImageSharp.fixed} />
            </div>
          </AccordionItem>

          <AccordionItem title="What if in the citations within the document to be linked (brief, motion, petition, or etc.) contain several different volumes or source documents. Can HyperCite accommodate those citation forms?">
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process documents to be linked that contain citations to
              multiple volumes or source documents. However, the practitioner must follow one
              guideline. A semicolon must be placed between different volumes or sources.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection2.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Are there special rules for instances where I use an Exhibit or Tab number, like for a writ petition?">
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process writ petitions, however, it is a circumstance
              that demands one extra step and adherence to some guidelines. When uploading a source
              document that contains Exhibit or Tab numbers, there is a check box on the HyperCite
              <sup>&reg;</sup> upload record source page that the practitioner must select.
              Moreover, the practitioner must keep some citation guidelines in mind.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection31.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection32.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              Although the above sample does not contain line numbers, line numbers may be employed
              with Exhibits or Tabs in accordance with Question #1 of this FAQ.
            </Typography>

            <Typography className="pb-12">
              Moreover, different volumes and source documents may be combined in the same string
              citation in accordance with Question #2 of this FAQ.
            </Typography>
          </AccordionItem>

          <AccordionItem title="What if my Clerk’s Transcript or Reporter’s Transcript bate stamp numbers do not match the Adobe system’s page numbers? (The Reporter or Clerk included unnumbered pages)">
            <Typography className="pb-12">
              In order for HyperCite<sup>&reg;</sup> to work properly, the page numbers displayed in
              the PDF viewer must match the bate stamp numbers of the reporter’s transcript, clerk’s
              transcript, or any other source document. The following illustrates when the PDF
              viewer and bates stamp numbers match each other
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection41.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              When HyperCite<sup>&reg;</sup> links citation page numbers to the appellate record, it
              utilizes the page number displayed by the PDF viewer, not the bates stamp number. When
              the page number displayed by the PDF viewer and bates stamp numbers are not the same,
              this will cause a problem. Citations citing to bates stamp page one when the numbers
              do not mate will not go to the proper page.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection42.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              The reason the page numbers do not match in the above example is the court reporter
              left the title page and various tables unnumbered. The reporter started bate stamp
              page one after six unnumbered pages. A similar problem can arise when the Clerk in
              preparing the Clerk’s Transcript decides to un-number the table of contents and title
              page. In order for HyperCite<sup>&reg;</sup> to work properly, the page numbers must
              be synced. The following illustrates how to sync the PDF viewer page number and bates
              number.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection43.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection44.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="What if my Clerk’s Transcript or Reporter’s Transcript bate stamp numbers do not match the Adobe system’s page numbers? (The Reporter’s or Clerk’s transcripts do not start at page 1)">
            <Typography className="pb-12">
              Sometimes, a Reporter or Clerk will start each date of testimony or volume at a page
              other than one. For example, your first Reporter’s Transcript starts at page 100. If
              your Reporter’s Transcript starts at bates stamp 100, Adobe will read that page as
              page one. HyperCite<sup>&reg;</sup> will link a citation to page 100 in the brief to
              page one. This can be fixed with page labels.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Img fluid={staticData.faqsSection51.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="w-full">
                <Img fluid={staticData.faqsSection52.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </Container>
    </PublicRoute>
  );
};

export default Faq;
